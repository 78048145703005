.Picker {
  display: flex;
  max-width: 80vw;

  &__image {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 2px solid black;
    border-radius: 5px;
    margin: 5px;

    &--active {
      border-color: red;
    }
  }
}
